/* eslint-disable ssr-friendly/no-dom-globals-in-module-scope */
/* eslint-disable no-console */
import "styles/fonts/typography.css"
import "the-new-css-reset/css/reset.css"
import React from "react"

import gsap from "gsap"
import { ScrollSmoother } from "gsap/ScrollSmoother"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Observer } from "gsap/Observer"

import Providers from "components/Providers"

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, Observer)

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => {
  return <Providers>{element}</Providers>
}

// if not localhost, disable gsap null checks
if (window.location.hostname !== "localhost") {
  gsap.config({
    nullTargetWarn: false,
  })
}

// export const onPreRouteUpdate = ({ location, prevLocation }) => {
//   if (location && prevLocation && location.pathname !== prevLocation.pathname) {
//     window.scrollTo(0, 0);
//   }
// };